.language-switcher {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin: 3px 0;
}

.language-switcher button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.language-switcher button img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.language-switcher button img:hover {
  transform: scale(1.1);
}